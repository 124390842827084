//const bugsnag = require('bugsnag-js')
//global.bugsnag = bugsnag(BUGSNAG_APIKEY)

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

// jQuery (defined as a global since so many plugins look for it in the global scope)
global.jQuery = require('jquery')

require("regenerator-runtime/runtime");

// rkt utils JS library
require('./utils')

// stop back button popping the scroll position
require('scroll-restoration-polyfill')
history.scrollRestoration = 'manual'

// main js at last
require('./main')
