import barba from '@barba/core'
const preloader = require('../utils/preload')
const lazyLoad = require('../utils/lazyLoad')
const commonFunction = require('./common.js')

// global barba hooks for preload/lazyload and other stuff if needed
var initialPreload = true
barba.hooks.beforeEnter(({next}) => {
	commonFunction(next.container)
	if(initialPreload){
		initialPreload = false
		preloader.start()
		return preloader.load({element: document.body })
	}
})
barba.hooks.afterEnter(({next}) => {
	lazyLoad(next.container)
})


// import all transitions in the barba transitions folder
const transitions = []
const t = require.context('./transitions/', false, /\.js$/)
t.keys().forEach(key => transitions.push(t(key)))

// import all views in the barba views folder
const views = []
const v = require.context('./views/', false, /\.js$/)
v.keys().forEach(key => views.push(v(key)))

barba.init({
	transitions,
	views,
	timeout: 5000,
	debug: process.env.BARBA_DEBUG == 'true' ? true : false
})
