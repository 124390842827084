import $ from 'jquery'
import 'gsap'
import 'waypoints/lib/noframework.waypoints.min.js';
import Swiper from 'swiper';
import SwiperCore, { EffectFade, Autoplay } from 'swiper/core';
// configure Swiper to use modules
SwiperCore.use([EffectFade, Autoplay]);

module.exports = {
	namespace: 'home',
	beforeEnter() {

	},
	afterEnter(){

		//screenheight helper
		var screenHeight = $(window).innerHeight()
		$('section#intro').each(function () {
			$(this).css('min-height', screenHeight + 'px')
		})
		$(window).on('resize', function () {
			var screenHeight = $(window).innerHeight()
			$('section#intro').each(function () {
				$(this).css('min-height', screenHeight + 'px')
			})
		})

		const swiper = new Swiper('.swiper-container', {
			autoplay: true,
			speed: 3000,
			effect: "fade",
			fadeEffect: {
				crossFade: true
			},
			loop: true,
			preloadImages: true,
			updateOnImagesReady: true,
		})

		$(window).on('load scroll', function(){
			var scrollTop = $(document).scrollTop();
			if (($(window).innerHeight() / 9) < scrollTop) {
				$('.mini-logo').removeClass('hide')
				$('.big-logo').addClass('hide')
			} else {
				// $('.mini-logo').addClass('hide')
				// $('.big-logo').removeClass('hide')
			}
		})

		$('.big-logo').on('click', function(){
			$(this).addClass('hide')
			$('.mini-logo').removeClass('hide')
		})

		// var waypoint = new Waypoint({
		// 	element: document.getElementById('logo'),
		// 	handler: function (direction) {
		// 		$('#logo').toggleClass('sticky')
		// 	},
		// 	offset: 5
		// })

		// var current = 0,
		// 	slides = $('.slide')

		// setInterval(function () {
		// 	for (var i = 0; i < slides.length; i++) {
		// 		slides[i].style.opacity = 0;
		// 	}
		// 	current = (current != slides.length - 1) ? current + 1 : 0;
		// 	slides[current].style.opacity = 1;
		// }, 6000);

	},
	beforeLeave(){

	},
	afterLeave() {

	}
}
