import $ from 'jquery'
import { gsap } from "gsap";
const navController = require('./utils/navController')
const setNavActive = require('./utils/setNavActive').init
const $document = $(document)

$document.ready(() => {

	// preloader setup
	require('./preload')

	// kicks off the barba stuff
	require('./barba')

	// Sample GSAP intro animation
	gsap.from("li", 1, {autoAlpha:0, y:'-5', delay:0, ease:"circ.inOut", force3D:true, stagger:0.5}, 0.1)

	navController({
		trigger: ".nav-trigger",
		setActive: "nav",
		autoClose: "nav a",
		breakPoint: 1440
	})

	setNavActive({
		"nav li": {
			a: "> a",
			fuzzy: false, // if you want fuzzy matching set to true
			parents: { top: "nav", element:"li" } // if your nav doesnt have nested items/sub navs, this isnt needed
		}
	})
})
