import $ from 'jquery'
import { TweenLite } from 'gsap'
const preloader = require('../../utils/preload')

module.exports = {

	// since the enter and leave animation has to happen at the same time, use sync mode
	sync: true,

	// rules to use to apply this transition. namespace arguments can be an array or string
	from: { namespace: ['home', 'about'] },
	to: { namespace: 'contact' },


	beforeEnter({next}){
		// prepare for the animation
		TweenLite.set(next.container, { x: '100%', position: 'absolute', ease:"expo.inOut", force3D:true })

		// return a promise that will resolve once images are preloaded for this page
		return preloader.load({element:next.container})
	},

	enter({next}) {
		// since gsap doesnt generate promises, use this.async() to get a done() function to call onComplete
		const done = this.async()

		// do the tween, calling done() onComplete
		TweenLite.to(next.container, 1, { x: '0%', ease:"expo.inOut", force3D:true, onComplete:() => done() })
	},

	afterEnter({next}){
		// do any cleanup for the enter animation if needed
	},


	beforeLeave({current}){
		// initialize leave animation
		TweenLite.set(current.container, { position: 'absolute' })

		// show the preloader
		preloader.start()
	},

	leave({current}) {
		// since gsap doesnt generate promises, use this.async() to get a done() function to call onComplete
		const done = this.async()

		// do the tween, calling done() onComplete
		TweenLite.to(current.container, 1, { x: '-100%', ease:"expo.inOut", force3D:true, onComplete:() => done() })
	},
	afterLeave({current}){
		// clean up after leave animation
		const $old = $(current.container)
		$old.css({top: "-"+window.scrollY+"px"})
		$(window).scrollTop(0)
		$old.hide()
	}
}
