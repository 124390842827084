import $ from 'jquery'

module.exports = {
	namespace: 'contact',
	beforeEnter() {

	},
	afterEnter() {

	},
	beforeLeave() {

	},
	afterLeave() {

	}
}
